import React, { useContext, useEffect, useState, useRef } from 'react';

import clsx from 'clsx';

import actions from '@/application/actions';
import validate from './validate';
import styles from './SignIn.module.scss';
import { useNavigate } from 'react-router';
import { UserContext } from '@contexts/App';
import { useQuery } from '@hooks';
import { connect } from 'react-redux';
import { useEventListener } from '@/hooks/ui';

function SignIn(props) {
  const {
    updateUser,
    actions: { login, updateUserRaw, initializeMasterDataFromToken },
  } = props;
  const query = useQuery();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const userNameRef = useRef(null);

  const INCORRECT_PWD = 'Tên đăng nhập hoặc mật khẩu không chính xác';

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({
    wrong: '',
    username: '',
    password: '',
  });

  useEventListener({
    event: 'keyup',
    callback: (e) => {
      const isInputFocus = document.activeElement?.tagName === 'INPUT';
      if (e.key === '/' && !isInputFocus) {
        userNameRef.current?.focus();
      }
    }
  })

  // Event handler
  useEffect(() => {
    setError((error) => ({
      ...error,
      username: '',
    }));
  }, [username]);

  useEffect(() => {
    setError((error) => ({
      ...error,
      password: '',
    }));
  }, [password]);

  useEffect(async () => {
    const redirect = query.get('redirect') || '/';

    if (userContext?.token) {
      navigate(redirect, { replace: true });
    }
  }, [userContext]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (validate(username, password, setError)) {
      const onSuccess = (data) => {
        localStorage.setItem('token', data.token);
        updateUser(data);
        updateUserRaw(data);
        initializeMasterDataFromToken(data.token);
      };
      const onFailure = () => {
        setError((error) => ({
          ...error,
          wrong: INCORRECT_PWD,
        }));
      };
      login({ username, password, onSuccess, onFailure });
    }
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.overlay}></div>

        <div className={styles.formContainer}>
          <div className={styles.header}>
            {/* <img src={shopConfig.images.logo} alt="Logo" className={styles.logo} /> */}
            <span>Đăng nhập</span>
          </div>

          <form className={styles.form} onSubmit={handleOnSubmit}>
            <label htmlFor="" className={styles.labelError}>
              {error.wrong}
            </label>

            <div
              className={clsx(styles.formWrapper, {
                [styles.error]: error.username,
              })}
              error={error.username}
            >
              <label htmlFor="username" className={styles.formLabel}>
                Tên đăng nhập
              </label>

              <input
                type="text"
                id="username"
                value={username}
                onChange={handleUsernameChange}
                className={clsx(
                  styles.formInput,
                  'border border-[#ccc] rounded',
                )}
                placeholder="Nhập tên đăng nhập"
                ref={userNameRef}
              />
            </div>

            <div
              className={clsx(styles.formWrapper, {
                [styles.error]: error.password,
              })}
              error={error.password}
            >
              <label htmlFor="password" className={styles.formLabel}>
                Mật khẩu
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                className={clsx(
                  styles.formInput,
                  'border border-[#ccc] rounded',
                )}
                placeholder="Nhập mật khẩu"
              />
            </div>

            <input
              type="submit"
              value="Đăng nhập"
              className={styles.submitButton}
            />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect(null, actions)(SignIn);
