import moment from 'moment';
import { ORDER_STATUS, ORDER_STATUS_NAMES } from '@config/constants';
import { memo, useEffect, useState, useRef, useCallback } from 'react';
import { Modal, Stack, Box, Typography, Button, TextField, IconButton, FormControl, FormHelperText } from '@mui/material';

import { validatePhone } from '@utilities/validate';
import { removeError } from '@screens/staff/Bill/validate';

import styles from './EditModal.module.scss';
import { Clear } from '@mui/icons-material';
import { SelectContainer } from '@/components/organisms';


const defaultError = {
  phone: '',
  price: '',
  paid: '',
};
const PHONE_LENGTH = 10;
/**
 * @param {{
 *   isOpen: boolean,
 *   bill: import('../../../../shared/types').IBill,
 *   onClose: () => void,
 * }} props
 * @returns import('react').FC
 */
const _EditModal = ({
  isOpen,
  bill,
  masterData,
  onSave,
  onClose,
  onChangeStatus,
}) => {
  const [editingBill, setEditingBill] = useState(null);
  const [error, setError] = useState(defaultError);

  const [phone, setPhone] = useState();

  // image handler
  const wrapperRef = useRef();
  const previewImg = useRef(null);
  const [img, setImg] = useState(' ');
  const [imgChange, setImgChange] = useState(false);

  const onInputChange = useCallback((e) => {
    const { name, value } = e.target;

    setEditingBill(
      (_editingBill) => ({
        ..._editingBill,
        [name]: value,
      }),
    );
  }, []);
  const preParsedPrice = v => v.replace(/[\.,]/g, '');
  const parsedPrice = (v) => +v.substring(0, 11);
  const formatPrice = (v) => v?.toLocaleString('vi-VN');
  const onPriceChange = useCallback((e) => {
    const { name, value } = e.target;
    const preValue = preParsedPrice(value);
    if (preValue.search(/[^0-9]/g) > -1) {
      setError(_err => (
        {
          ..._err,
          [name]: 'Chỉ nhập số từ 0-9'
        }
      ));
      return;
    }
    const isPrice = name === 'price';
    const toComparedKey = isPrice ? 'paid' : 'price';
    const newValue = parsedPrice(preValue);
    const diff = newValue - editingBill[toComparedKey];
    if ((isPrice && diff < 0) || (!isPrice && diff > 0)) {
      setError(_err => (
        {
          ..._err,
          [name]: 'Yêu cầu tiền gốc >= tiền đã trả'
        }
      ));
      return;
    }
    setError(_err => (
      {
        ..._err,
        [name]: '',
      }
    ));
    setEditingBill(
      (_editingBill) => ({
        ..._editingBill,
        [name]: newValue || 0,
      }),
    );
  }, [editingBill]);

  const handlePhoneChange = useCallback((e) => {
    const { value } = e.target;
    const errorMessage = validatePhone(value);

    if (!errorMessage) {
      if (value.length >= PHONE_LENGTH) {
        setEditingBill(_editingBill => ({
          ..._editingBill,
          phones: [...new Set([..._editingBill.phones, value.substring(0, PHONE_LENGTH)])],
        }));
        setPhone('');
      } else {
        setPhone(value);
      }
    }

    setError(_err => ({
      ..._err,
      phone: errorMessage
    }));
  }, []);

  const handleRemovePhone = useCallback((phone) => () => {
    setEditingBill(_editingBill => ({
      phones: _editingBill.phones.filter(_phone => _phone !== phone),
    }));
  }, []);

  const handleChangeStatusClick = useCallback(
    async (editingBill) => {
      const nextStatus = editingBill.statusID === ORDER_STATUS.WAITING ? ORDER_STATUS.SUCCESS : ORDER_STATUS.WAITING;
      await onChangeStatus(editingBill);
      setEditingBill(_editingBill => ({
        ..._editingBill,
        status: nextStatus,
      }))
    }, [editingBill]);

  const handleSave = () => {
    onSave(
      editingBill,
      imgChange ? img : '',
      onClose,
    );
  };

  // Paste handling
  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.onpaste = (e) => {
        const data = e.clipboardData;
        if (data.files.length > 0) {
          const urlImage = URL.createObjectURL(data.files[0]);
          previewImg.current.src = urlImage;
          setImg(data.files[0]);;

          return () => {
            URL.revokeObjectURL(urlImage);
          }
        }
        setImgChange(true);
      };
    }

    return () => { }
  }, []);

  useEffect(() => {
    document.querySelectorAll(`.${styles.error}`).forEach((node) => {
      node.querySelector('input').onfocus = () => {
        removeError(setError, node.id);
      };
    });
  }, [error]);

  useEffect(() => {
    if (previewImg?.current) {
      previewImg.current.src = bill?.imgURL;
    }

    setEditingBill({
      ...bill,
      phones: (bill?.phones || []).map(({ phone }) => phone),
    });
    setError({
      ...defaultError,
    });
  }, [bill]);

  if (!isOpen || !editingBill) {
    return <></>
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    // sx={{ maxHeight: '80vh', overflow: 'auto' }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          fontSize: "16px",
          maxHeight: '80vh',
          overflow: 'auto',
        }}
        ref={wrapperRef}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="8px" justifyContent="center">
            <Typography variant="h5" fontSize="16px">
              ĐƠN #{editingBill.id}
            </Typography>

            <Typography variant="h4" fontSize="14px">
              (Trang thái: {ORDER_STATUS_NAMES[editingBill.statusID]})
            </Typography>
          </Stack>

          <Button
            variant="contained"
            onClick={() => handleChangeStatusClick(editingBill)}
            sx={{ fontSize: "16px" }}
          >
            {editingBill.statusID === ORDER_STATUS.WAITING ?
              ORDER_STATUS_NAMES[ORDER_STATUS.COMPLETE] :
              ORDER_STATUS_NAMES[ORDER_STATUS.WAITING]
            }
          </Button>
        </Stack>

        <Stack direction="column" gap="8px" marginTop="20px">
          {editingBill.employee?.displayName && (
            <Stack direction="row" gap="8px" alignItems="center">
              <Typography variant="h6" fontSize="14px" minWidth="100px">
                Người tạo
              </Typography>

              <Typography variant="span" fontSize="14px">
                : {editingBill.employee?.displayName}
              </Typography>
            </Stack>
          )}

          <Stack
            direction="row"
            gap="8px"
            alignItems="center"
          >
            <Typography variant="h6" fontSize="14px" minWidth="100px">
              Ngày tạo
            </Typography>

            <Typography variant="span" fontSize="14px">
              : {moment(editingBill?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            gap="8px"
            alignItems="center"
          >
            <Typography variant="h6" fontSize="14px" minWidth="100px">
              Ngày cập nhật
            </Typography>

            <Typography variant="span" fontSize="14px">
              : {moment(editingBill?.updatedAt).format("DD/MM/YYYY HH:mm:ss")}
            </Typography>
          </Stack>

          <_Input
            name="name"
            label="Tên khách hàng"
            value={editingBill.name}
            onChange={onInputChange}
            mt
            fullWidth
          />

          <_Phone
            phone={phone}
            phones={editingBill.phones}
            onChange={handlePhoneChange}
            onRemove={handleRemovePhone}
            error={error.phone}
          />

          <_Input
            name="address"
            label="Địa chỉ"
            value={editingBill.address}
            onChange={onInputChange}
            multiline
            mt
            fullWidth
          />

          <_Input
            name="price"
            label="Tiền gốc"
            value={formatPrice(editingBill.price)}
            onChange={onPriceChange}
            error={error.price}
            mt
            fullWidth
          />

          <_Input
            name="paid"
            label="Khách đã trả"
            value={formatPrice(editingBill.paid)}
            onChange={onPriceChange}
            error={error.paid}
            mt
            fullWidth
          />

          <_Select
            label="Sản phẩm"
            name="productID"
            value={editingBill.productID}
            options={masterData.products}
          />

          <_Select
            label="Đơn vị vận chuyển"
            name="shipServiceID"
            value={editingBill.shipServiceID}
            options={masterData.shipServices}
          />

          <_Select
            label="Trang TMĐT"
            name="ecommerceID"
            value={editingBill.ecommerceID}
            options={masterData.ecommerces}
          />

          <_Input
            name="ecommerceName"
            label="Tên trang TMĐT"
            value={editingBill.ecommerceName}
            onChange={onInputChange}
            mt
            fullWidth
          />

          <_Input
            name="note"
            label="Ghi chú"
            value={editingBill.note}
            onChange={onInputChange}
            mt
            fullWidth
          />

          <Stack
            sx={{
              '& img': {
                width: '100%',
                objectFit: 'contain',
              }
            }}
          >
            <img
              src={editingBill.imgURL}
              alt="Hình ảnh"
              className={styles.img}
              ref={previewImg}
            />
          </Stack>
        </Stack>

        <Stack direction="row" gap="8px" justifyContent="flex-end">
          <Button sx={{ mt: 2 }} variant="outlined" onClick={onClose}>
            Đóng
          </Button>

          <Button sx={{ mt: 2 }} variant="contained" onClick={handleSave}>
            Lưu
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

const __Phone = ({ phone, phones, error, onChange, onRemove }) => {
  const handleRemovePhone = useCallback(
    (phone) => onRemove(phone),
    [phones],
  );

  return (
    <Stack marginTop="8px">
      <_Input
        name="phone"
        label="SĐT"
        value={phone}
        onChange={onChange}
        error={error}
      />

      <Stack
        direction="row" gap="4px" marginTop="4px" flexWrap="wrap">
        {phones.map((phone) => (
          <Stack
            key={phone}
            className={styles.phoneBox}
            direction="row"
            borderRadius="16px"
            justifyContent="space-between"
            alignItems="center"
            paddingLeft="8px"
          >
            <Typography fontSize="12px">
              {phone}
            </Typography>

            <IconButton
              sx={{
                color: 'white',
                padding: '4px 8px',
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              onClick={handleRemovePhone(phone)}
            >
              <Clear />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
};
const _Phone = memo(__Phone);

const __Input = ({ mt, sx, inputProps, error, ...props }) => (
  <FormControl error={error}>
    <TextField
      {...props}
      InputLabelProps={{
        shrink: true,
        style: { fontSize: '16px' }
      }}
      InputProps={{
        className: styles.inputWrapper,
        max: 10,
        style: {
          fontSize: '16px',
        },
        inputProps,
      }}
      sx={
        {
          ...(mt ? { marginTop: '8px' } : {}),
          ...(sx || {}),
        }
      }
    />
    {error && (<FormHelperText sx={{ padding: 0, marginLeft: 0 }}>{error}</FormHelperText>)}
  </FormControl>
);
const _Input = memo(__Input);

const __Select = ({ mt, sx, ...props }) => (
  <SelectContainer
    {...props}
    selectClassName={styles.selectWrapper}
    sx={
      {
        ...(mt ? { marginTop: '8px' } : {}),
        ...(sx || {}),
      }
    }
  />
);
const _Select = memo(__Select);

export const EditModal = memo(_EditModal);

