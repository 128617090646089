import moment from 'moment';
import { useMemo, useRef, memo } from 'react';
import { Button, Card, CardContent, CardActions, Stack, Typography } from '@mui/material';
import styles from './styles.module.scss';

const COLS = {
  FIRST: {
    id: 'MÃ ĐƠN',
    address: 'ĐỊA CHỈ',
    productName: 'SẢN PHẨM',
    name: 'KHÁCH HÀNG',
    phone: 'SĐT',
    ecommerce: 'TRANG TMĐT',
    shipService: 'ĐVVC',
    createdAt: 'NGÀY TẠO',
    status: 'TÌNH TRẠNG',
    price: 'ST cần thu',
  },
};

/**
 *
 * @param {{
 *   bills: import('../BillTable').IBill[];
 *   handleEditClick: (id: number) => void;
 *   handleDeleteClick: (id: number) => void;
 * }} param0
 * @returns
 */
const MobileBillTable = (
  {
    bills,
    handleDeleteClick,
    handleEditClick,
  },
) => {
  const displayBills = useMemo(
    () =>
      bills.map((bill) => ({
        ...bill,
        id: bill.id,
        address: bill.address,
        name: bill.name,
        productName: bill.product.name,
        phone: bill.phones.map((phone) => phone.phone).join(', '),
        ecommerce: bill.ecommerce.name,
        shipService: bill.shipService.name,
        createdAt: moment(bill.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        status: bill.status.name,
        price: (bill.price - bill.paid).toLocaleString('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }),
      })),
    [bills],
  );

  return (
    <Stack
      sx={{ margin: '16px 16px 0', gap: '16px' }}
    >
      {displayBills.map((bill, index) => (
        <Card
          key={bill.id}
          sx={{
            borderRadius: '8px',
          }}
        >
          <CardContent sx={{
            display: 'flex',
            fontSize: '14px',
            flexDirection: 'column',
          }}>
            {Object.entries(COLS.FIRST).map(([key, label]) => (
              <Typography key={key} variant="span" sx={{ fontWeight: 'bold' }}>
                <Typography
                  variant="span"
                  sx={{
                    display: 'inline-block',
                    minWidth: '100px',
                    fontWeight: 'normal',
                  }}>
                  {label}
                </Typography>
                : {bill[key]}
              </Typography>
            ))}

            <img
              loading="lazy"
              src={bill.imgURL}
              alt="Hình ảnh con dấu"
              className={styles.productImg}
            />
          </CardContent>

          <CardActions
            sx={{
              justifyContent: 'flex-end',
              fontSize: '14px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleEditClick(bills[index])}
            >
              Sửa
            </Button>

            <Button
              variant="contained"
              color="error"
              onClick={() =>
                handleDeleteClick(bill.id)}>
              Xóa
            </Button>
          </CardActions>
        </Card>
      ))}
    </Stack>
  );
};

export default memo(MobileBillTable);

