import clsx from 'clsx';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import MobileUI from '../MobileUI';
import '@assets/image/fontawesome-free-6.0.0-web/css/all.min.css';
import styles from './BillTable.module.scss';

function BillTable(
  {
    bills,
    checked,
    handleEditClick,
    handleMobileEditClick,
    handleDeleteClick,
    handleSelectMouseEnter,
    handleSelectMouseDown,
    handleSortOrderChange,
    mousePressed,
    setMousePressed,
    hideFilter,
    error,
    order,
  },
  ref,
) {
  const tableBody = useRef();
  const tableMobileBody = useRef();
  const [imgZoom, setImgZoom] = useState(false);

  const addEventListener = (event, cb) =>
    tableBody.current?.addEventListener(event, cb);
  const removeEventListener = (event, cb) =>
    tableBody.current?.removeEventListener(event, cb);

  const mobileAddEventListener = (event, cb) =>
    tableMobileBody.current?.addEventListener(event, cb);
  const mobileRemoveEventListener = (event, cb) =>
    tableMobileBody.current?.removeEventListener(event, cb);

  useImperativeHandle(
    ref,
    () => ({
      tableBody: {
        addEventListener,
        removeEventListener,
      },
      tableMobileBody: {
        addEventListener: mobileAddEventListener,
        removeEventListener: mobileRemoveEventListener,
      }
    }),
    [tableBody, tableMobileBody],
  );

  return (
    <div>
      <div className={clsx(styles.tableMobile, {
        [styles.expand]: hideFilter,
      })} ref={tableMobileBody}>
        <MobileUI
          bills={bills}
          handleDeleteClick={handleDeleteClick}
          handleEditClick={handleMobileEditClick}
        />
      </div>
      <div className={clsx(styles.tableGrid)}>
        {/* Heading: Start */}
        <div className={clsx('textCenter', styles.headerGrid)}>
          <div
            className={clsx(styles.smallCol, styles.heading, {
              [styles.error]: error.unSelectedBill,
            })}
            error={error.unSelectedBill}
          >
            Chọn
          </div>
          <div
            className={clsx(styles.heading)}
            onClick={() => handleSortOrderChange('id')}
          >
            Mã đơn
            <i
              className={clsx(styles.sortIcon, 'fa-solid', {
                'fa-sort-up': order.id === 'ASC',
                'fa-sort-down': order.id === 'DESC',
                'fa-sort': order.id === '',
              })}
            />
          </div>
          <div
            className={clsx(styles.heading)}
            onClick={() => handleSortOrderChange('createdAt')}
          >
            Ngày tạo
            <i
              className={clsx(styles.sortIcon, 'fa-solid', {
                'fa-sort-up': order.createdAt === 'ASC',
                'fa-sort-down': order.createdAt === 'DESC',
                'fa-sort': order.createdAt === '',
              })}
            />
          </div>
          <div
            className={clsx(styles.heading)}
            onClick={() => handleSortOrderChange('name')}
          >
            Khách hàng
            <i
              className={clsx(styles.sortIcon, 'fa-solid', {
                'fa-sort-up': order.name === 'ASC',
                'fa-sort-down': order.name === 'DESC',
                'fa-sort': order.name === '',
              })}
            />
          </div>
          <div className={clsx(styles.heading)}>SĐT</div>
          <div className={clsx(styles.heading)}>Địa chỉ</div>
          <div
            className={clsx(styles.heading)}
            onClick={() => handleSortOrderChange('price')}
          >
            ST Cần Thu
            <i
              className={clsx(styles.sortIcon, 'fa-solid', {
                'fa-sort-up': order.price === 'ASC',
                'fa-sort-down': order.price === 'DESC',
                'fa-sort': order.price === '',
              })}
            />
          </div>
          <div className={clsx(styles.heading)}>Trang TMĐT</div>
          {/* <div className={clsx(styles.heading)}>Sản phẩm</div> */}
          <div className={clsx(styles.heading)}>ĐVVC</div>
          <div className={clsx(styles.heading)}>Tình trạng</div>
          <div className={clsx(styles.heading)}>Hình ảnh</div>
          <div className={clsx(styles.heading)}>Sửa</div>
        </div>
        {/* Heading: End */}

        <div className={clsx(styles.tableBody)} ref={tableBody}>
          {!bills.length && (
            <h3 className={styles.emptyBills}>Không có sản phẩm</h3>
          )}
          {bills.map((bill) => {
            return (
              <div
                key={bill.id}
                className={clsx(styles.tableRowGrid, {
                  textUnselect: mousePressed,
                  [styles.hightlight]: bill.statusID === 1,
                })}
              >
                {/* Select  */}
                <div
                  className={clsx(styles.tableBodyRowContent)}
                  onMouseEnter={handleSelectMouseEnter}
                  onMouseDown={() => {
                    setMousePressed(true);
                    handleSelectMouseDown(bill.id);
                  }}
                  id={bill.id}
                >
                  <input
                    type="checkbox"
                    name="selected-bills"
                    onMouseEnter={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={() => { }}
                    checked={checked[bill.id] || false}
                  />
                </div>

                {/* Bill ID */}
                <div className={clsx(styles.tableBodyRowContent)}>
                  {bill.id}
                </div>

                {/* Bill Created Date */}
                <div className={clsx(styles.tableBodyRowContent)}>
                  <p>
                    <span>
                      {new Date(bill.createdAt).toLocaleDateString('vi-VN')}
                    </span>
                    <span>
                      {new Date(bill.createdAt).toLocaleTimeString('vi-VN')}
                    </span>
                  </p>
                </div>

                {/* Customer Name */}
                <div className={clsx(styles.tableBodyRowContent)}>
                  {bill.name}
                </div>

                {/* Customer Phone */}
                <div className={clsx(styles.tableBodyRowContent)}>
                  <p>
                    {bill.phones.map((phone) => (
                      <span key={phone.phone}>{phone.phone}</span>
                    ))}
                  </p>
                </div>

                {/* Address */}
                <div
                  className={clsx(styles.tableBodyRowContent, 'textLeftFlex')}
                >
                  {bill.address}
                </div>

                {/* Price */}
                <div className={clsx(styles.tableBodyRowContent)}>
                  {(bill.price - bill.paid).toLocaleString('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  })}
                </div>

                {/* Ecommerce */}
                <div
                  className={clsx(
                    'textCenter',
                    'textUppercase',
                    styles.tableBodyRowContent,
                  )}
                >
                  <p>
                    <span className="textBold">{bill.ecommerceName}</span>
                    <span>{bill.ecommerce && bill.ecommerce.name}</span>
                  </p>
                </div>

                {/* Product Name */}
                {/* <div
                                className={clsx(
                                    'textUppercase',
                                    styles.tableBodyRowContent
                                )}
                            >
                                {bill.product.name}
                            </div> */}

                {/* Ship Service */}
                <div
                  className={clsx('textUppercase', styles.tableBodyRowContent)}
                >
                  {bill.shipService.name}
                </div>

                {/* Status */}
                <div
                  className={clsx('textUppercase', styles.tableBodyRowContent)}
                >
                  <p>{bill.status.name}</p>
                </div>

                {/* Product Image */}
                <div
                  className={clsx(styles.tableBodyRowContent, {
                    [styles.zoom]: imgZoom,
                  })}
                  onClick={() => setImgZoom(!imgZoom)}
                >
                  <img
                    loading="lazy"
                    src={bill.imgURL}
                    alt="Hình ảnh con dấu"
                    className={styles.productImg}
                  />
                </div>

                {/* Edit */}
                <div
                  className={clsx(styles.tableBodyRowContent)}
                  onClick={() => handleEditClick(bill)}
                >
                  <i className={clsx('fas fa-edit')} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default forwardRef(BillTable);

/**
 * @typedef IBill
 * @property {number} id
 * @property {string} name
 * @property {string} address
 * @property {string} price
 * @property {string} ecommerceName
 * @property {string} imgURL
 * @property {string} statusID
 * @property {{
 *    name: string
 * }} status
 * @property {{
 *   name: string
 * }} shipService
 * @property {{
 *   name: string
 * }} product
 * @property {{
 *   phone: string
 * }[]} phones
 * @property {{
 *  id: number
 * name: string
 * }} ecommerce
 * @property {number} paid
 * @property {string} createdAt
 */

