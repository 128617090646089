import { fetcher } from '@utilities';
import { billService } from '@services';
import * as notifyConstant from '@components/NotifyModal/constant';
import unitOfWork from '@controllers';
import _ from 'lodash';
import { shopConfig } from '@/config/shop-config';

const fetchSelectionOptions = (setProducts, setStatuses) => {
  const cancelController = new AbortController();

  unitOfWork.product.getAll(cancelController.signal, setProducts);

  unitOfWork.status.getAll(cancelController.signal, setStatuses);

  return cancelController;
};

const fetchBills = (
  page,
  startDate,
  endDate,
  selectedShipService,
  selectedStatus,
  selectedProduct,
  orders,
  bills,
  setBills,
  setTotalBillCount,
  setStopScrollEvent,
) => {
  const cancelController = new AbortController();

  unitOfWork.bill.getBillPage(
    cancelController.signal,
    page,
    startDate,
    endDate,
    selectedShipService,
    selectedStatus,
    selectedProduct,
    orders,
    bills,
    setBills,
    setTotalBillCount,
    setStopScrollEvent,
  );

  return cancelController;
};

const showErrorModal = (
  action,
  error,
  setErrorModalShow,
  setErrorModal,
  type = notifyConstant.ERROR,
) => {
  setErrorModalShow(true);
  setErrorModal({
    type: type,
    title: action,
    content: error,
  });
};

const showModal = (modal, setModal, setModalShow) => {
  setModal(modal);
  setModalShow(true);
};

// Event helper
const searchBills = (setBills, searchText, selectedSearchType, orders) => {
  const cancelController = new AbortController();

  unitOfWork.bill.search(
    cancelController.signal,
    searchText,
    selectedSearchType,
    orders,
    setBills,
  );

  return cancelController;
};

const getSelectedBills = (checked) => {
  return Object.keys(checked).filter((index) => checked[index]);
};

const updateStatus = (ids, fetchBills, statusID, setBillTemp) => {
  billService
    .updateStatus(ids, statusID)
    .then((res) => res.json())
    .then(() => {
      fetchBills();

      if (setBillTemp) {
        setBillTemp((billTemp) => ({
          ...billTemp,
          statusID,
        }));
      }
    });
};

const printBill = (ids, bills, setError, fetchBills) => {
  const billByID = _.keyBy(bills, 'id');

  ids = ids.filter((id) => billByID[id].shipService.name !== 'Shopee');
  updateStatus(ids, fetchBills, 2);

  if (ids.length > 0) {
    window.open(`/print?id=${ids}`, 'PrintBillTab', 'noopener,noreferrer');
  } else {
    setError((err) => ({
      ...err,
      unSelectedBill: 'Chọn ít nhất 1 đơn để in',
    }));
  }
};

const exportExcel = (checkedIds) => {
  if (!checkedIds.length) {
    return;
  }

  fetcher(`/api/bill/download?id=${checkedIds.join(',')}`)
    .then((res) => res.blob())
    .then((file) => {
      const url = URL.createObjectURL(file);
      window.open(url);
    })
    .catch((error) => console.log(error));
};

const deleteBill = (
  ids,
  setError,
  fetchBills,
  setErrorModalShow,
  setErrorModal,
) => {
  if (ids.length === 0) {
    setError((error) => ({
      ...error,
      unSelectedBill: 'Chọn ít nhất 1 đơn để xóa',
    }));
    return;
  }
  fetcher(`/api/bill/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(ids),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        showErrorModal(
          'Xóa',
          'Bạn không có quyền thực hiện việc này',
          setErrorModalShow,
          setErrorModal,
        );
      } else {
        setTimeout(fetchBills, 500);
      }
    })
    .catch((error) => console.log(error));
};

const updateShipService = (
  shipServiceInfo,
  setErrorModalShow,
  setErrorModal,
) => {
  billService
    .updateShipService(shipServiceInfo)
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        showErrorModal(
          'Cập nhật',
          data.error,
          setErrorModalShow,
          setErrorModal,
        );
      } else {
        showErrorModal(
          'Cập nhật',
          `Bạn đã cập nhật thành công ${data.count} đơn`,
          setErrorModalShow,
          setErrorModal,
          notifyConstant.SUCCESS,
        );
      }
    });
};

// Event handler
const handleCheckedAll = async (
  isCheckedAll,
  setChecked,
  currentBillCount,
  totalBillCount,
  startDate,
  endDate,
  selectedShipService,
  selectedStatus,
  selectedProduct,
  orders,
  setBills,
) => {
  const nextIsCheckedAll = !isCheckedAll;

  if (currentBillCount !== totalBillCount && nextIsCheckedAll) {
    const cancelController = new AbortController();

    const result = await unitOfWork.bill.getAll(
      cancelController.signal,
      startDate,
      endDate,
      selectedShipService,
      selectedStatus,
      selectedProduct,
      orders,
      setBills,
      setChecked,
      nextIsCheckedAll,
    );

    if (!result?.error) {
      setBills(result);
      setChecked(() =>
        result.reduce((prev, { id: billId }) => {
          prev[billId] = true;
          return prev;
        }, {}),
      );
    }

    return cancelController;
  } else {
    setChecked((checked) =>
      Object.keys(checked).reduce(
        (prev, item) => ({
          ...prev,
          [item]: nextIsCheckedAll,
        }),
        {},
      ),
    );
  }
};

const handleSelectMouseEnter = (e, mousePressed, setChecked) => {
  if (mousePressed) {
    const id = parseInt(e.target.id);
    setChecked((checked) => ({
      ...checked,
      [id]: !checked[id],
    }));
  }
};

const handleSelectMouseDown = (sid, setChecked) => {
  const id = parseInt(sid);
  setChecked((checked) => ({
    ...checked,
    [id]: !checked[id],
  }));
};

const closeSideBar = (setSelectedBill) => {
  setSelectedBill({
    bill: null,
    sideBarShown: false,
  });
};

const handleProductChange = (e, setSelectedProduct) => {
  setSelectedProduct(e.target.value);
};

const handleEditClick = (bill, setSelectedBill) => {
  setSelectedBill({
    bill: bill,
    sideBarShown: true,
  });
};

const handleSearchInputKeyUp = (
  setBills,
  searchText,
  selectedSearchType,
  orders,
) => {
  if (searchText) {
    return searchBills(setBills, searchText, selectedSearchType, orders);
  }
};

const handleSelectedStatusChange = (e, setSelectedStatus) => {
  setSelectedStatus(e.target.value);
};

const handleSelectShipServiceChange = (e, setSelectedShipService) => {
  setSelectedShipService(e.target.value);
};

const handleExportExcelClick = (checked) => {
  exportExcel(checked);
};

const handleDeleteBills = (
  checked,
  setError,
  setModal,
  setModalShow,
  fetchBills,
  setErrorModalShow,
  setErrorModal,
) => {
  const ids = getSelectedBills(checked);
  const actionTitle = 'Xóa';
  const actionConfirm = `Bạn có chắc muốn xoá ${ids.length} đơn không?`;
  const actionConduct = () =>
    deleteBill(ids, setError, fetchBills, setErrorModalShow, setErrorModal);
  showModal(
    { actionTitle, actionConfirm, actionConduct },
    setModal,
    setModalShow,
  );
};

const handleSortOrderChange = (col, setOrder) => {
  setOrder((order) => {
    const orders = ['ASC', 'DESC', ''];
    const index = orders.indexOf(order[col]);
    return {
      ...order,
      [col]: orders[(index + 1) % orders.length],
    };
  });
};

const handleSideBarUpdateClick = (
  { setModal, setModalShow },
  { actionConduct },
) => {
  const actionTitle = 'Cập nhật';
  const actionConfirm = `Bạn có chắc muốn cập nhật đơn này không?`;
  showModal(
    { actionTitle, actionConfirm, actionConduct },
    setModal,
    setModalShow,
  );
};

const handlePrint = (
  checkedIds,
  bills,
  setError,
  setModal,
  setModalShow,
  fetchBills,
) => {
  const actionTitle = 'In';
  const actionConfirm =
    `Bạn có chắc muốn in ${checkedIds.length} đơn này không?` +
    `Chú ý: Sau khi in đơn sẽ trở thành THÀNH CÔNG`;
  const actionConduct = () => printBill(checkedIds, bills, setError, fetchBills);
  showModal(
    { actionTitle, actionConfirm, actionConduct },
    setModal,
    setModalShow,
  );
};

const handleRetailPrint = (selectedIds) => {
  if (selectedIds.length) {
    window.open(
      `${shopConfig.routes.printRetail}?id=${selectedIds[0]}`,
      'PrintRetailBillTab',
      'noopener,noreferrer',
    );
  }
};

const handleChangeStatusClick = (
  bill,
  setModal,
  setModalShow,
  fetchBills,
  setBillTemp,
  { actionConduct },
) => {
  const actionTitle = 'Xác nhận';
  const actionConfirm = `Bạn có chắc chuyển đơn này thành ${bill.statusID === 1 ? 'THÀNH CÔNG' : 'CHỜ GIA CÔNG'
    } không?`;
  // const actionConduct = () => {
  //     const newStatus = bill.statusID === 1 ? 2 : 1;

  //     updateStatus(bill.id, fetchBills, newStatus, setBillTemp);
  // };
  showModal(
    { actionTitle, actionConfirm, actionConduct },
    setModal,
    setModalShow,
  );
};

const handleShipServiceInfoChange = (e, setShipServiceInfo) => {
  setShipServiceInfo(e.target.files[0]);
};

export {
  showErrorModal,
  fetchSelectionOptions,
  fetchBills,
  printBill,
  exportExcel,
  deleteBill,
  searchBills,
  updateShipService,
  showModal,
  closeSideBar,
  handleProductChange,
  handleCheckedAll,
  handleSelectMouseEnter,
  handleSelectMouseDown,
  handleEditClick,
  handleSearchInputKeyUp,
  handleSelectedStatusChange,
  handleSelectShipServiceChange,
  handleExportExcelClick,
  handleDeleteBills,
  handleSortOrderChange,
  handleSideBarUpdateClick,
  handleRetailPrint,
  handlePrint,
  handleChangeStatusClick,
  handleShipServiceInfoChange,
};
