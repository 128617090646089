import { Buffer } from 'buffer';
import makeCancellablePromise from './makeCancellablePromise';

const env = process.env.NODE_ENV || 'development';

const encode = (str) => Buffer.from(str, 'utf8').toString('base64');

const decode = (str) => Buffer.from(str, 'base64').toString('utf8');

const updateOptions = (options) => {
  const update = { ...options };

  if (localStorage.getItem('token')) {
    update.headers = {
      ...update.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
  }

  return update;
};

const fetcher = (url, options) => {
  // const _url = `https://noibo.khacdauquoctien.com${url}`;
  const _url = `${url}`;
  return fetch(_url, updateOptions(options));
};

const getFileNameFromHeader = (response) => {
  var filename = '';
  var disposition = response.headers.get('Content-Disposition');

  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }

    return filename;
  }
};

const stopPropagation = (e) => {
  e.stopPropagation();
};

const convertCamelCaseString = (str, splitter = ' ') =>
  str.replace(/[^A-Z][A-Z]/g, (s) => `${s[0]}${splitter}${s[1]}`);

const normalizeUnicode = (str, { lowercase } = { lowercase: true }) => {
  let result = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  if (lowercase) result = result.toLowerCase();

  return result;
};

const logger = env === 'development' ? console : null;

const replaceParams = (str = '', mappingObject = {}) => {
  const regKey = Object.keys(mappingObject)
    .map((key) => `:${key}`)
    .join('|');
  const reg = new RegExp(regKey, 'g');

  return str.replaceAll(reg, (match) => mappingObject[match.substring(1)]);
};

const replaceBy = (str = '', mappingObject = {}) => {
  const regKey = Object.keys(mappingObject).join('|');
  const reg = new RegExp(regKey, 'g');

  return str.replaceAll(reg, (match) => mappingObject[match]);
};

export {
  fetcher,
  encode,
  decode,
  convertCamelCaseString,
  makeCancellablePromise,
  getFileNameFromHeader,
  stopPropagation,
  normalizeUnicode,
  logger,
  replaceParams,
  replaceBy,
};
