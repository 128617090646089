const validatePhone = (value) => {
  if (value.search(/[^0-9]/g) > -1) {
    return 'Chỉ nhập số từ 0-9';
  }

  return null;
};

const validateVND = (value) => {
  const checkedValue = value.replace(/\./g, '');
  const length = checkedValue.length;
  if (length === 0) {
    return '0';
  } else if (checkedValue.search(/[^0-9]/g) > -1) {
    return false;
  }
  return checkedValue.substring(0, 11);
};

const validatePrice = (value, setBill, paid, setError) => {
  const isValidate = validateVND(value, setBill);

  if (isValidate) {
    const newValue = parseInt(isValidate);
    if (newValue >= paid) {
      setBill((bill) => ({
        ...bill,
        price: newValue,
      }));
    }
  } else {
    setError((error) => ({
      ...error,
      price: 'Chỉ nhập số từ 0-9',
    }));
  }
};

const validatePaid = (value, setBill, price, setError) => {
  const isValidate = validateVND(value, setBill);
  if (isValidate) {
    const newValue = parseInt(isValidate);
    if (newValue <= price) {
      setBill((bill) => ({
        ...bill,
        paid: newValue,
      }));
    }
  } else {
    setError((error) => ({
      ...error,
      paid: 'Chỉ nhập số từ 0-9',
    }));
  }
};

const validateBill = (bill, setError, img) => {
  const newError = {
    name: bill.name ? '' : 'Nhập họ tên khách hàng',
    phone: bill.phones.length ? '' : 'Nhập số điện thoại khách hàng',
    address:
      bill.address || bill.ecommerce?.name === 'Khách tới lấy'
        ? ''
        : 'Nhập địa chỉ khách hàng',
    ship: bill.shipService.id ? '' : 'Chọn đơn vị vận chuyển',
    product: bill.productID ? '' : 'Chọn sản phẩm',
    ecommerceName:
      bill.ecommerceName || bill.ecommerceID === 6 ? '' : 'Nhập trang TMĐT',
    ecommerceID: bill.ecommerceID ? '' : 'Chọn trang TMĐT',
    img: img ? '' : 'Upload ảnh trước khi tạo đơn',
  };
  setError((error) => ({ ...error, ...newError }));
  return !Object.values(newError).some(Boolean);
};

export {
  validatePhone,
  validatePrice,
  validatePaid,
  validateBill,
};
