import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { memo } from 'react';

const SelectContainer = ({
  label,
  error,
  name,
  options,
  value,
  onChange,
  hiddenLabel = false,
  selectProps,
  selectClassName,
  sx = {},
  selectSx = {},
  helperTextSx = {},
}) => {
  return (
    <FormControl sx={{ ...sx }} error={error}>
      {!hiddenLabel && (
        <InputLabel sx={{ fontSize: '1.6rem' }} id={`${name}-select-label`}>
          {label}
        </InputLabel>
      )}

      <Select
        id={`${name}-select`}
        className={selectClassName}
        name={name}
        value={value}
        onChange={onChange}
        sx={{
          fontSize: '1.6rem',
          outline: 'none',
          ...selectSx,
        }}
        {...(!hiddenLabel && {
          labelId: `$name}-select-label`,
          label,
        })}
      >
        {options.map((status) => (
          <MenuItem
            value={status.id}
            key={status.id}
            sx={{
              fontSize: '1.6rem',
            }}
          >
            {status.name}
          </MenuItem>
        ))}
      </Select>

      {error && (
        <FormHelperText sx={{
          ...(selectSx || {}),
        }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default memo(SelectContainer);
